// Тут основные параметры редактора, сам редактор подключается как обычный компонент
// с параметрами v-model="..." :apiKey="getEditorKey" :init="getEditorOptions", последние два через computed

export default {
  state: {
    apiKey: 'cpf0twou1l5tzyr6qq0t8z3ot56gu6r7udxztfxnvwnygxfl',
    editorOptions: {
      height: '300',
      width: '950',
      language: 'ru',
      resize: 'both',
      // setup: function(editor) {
      //   editor.on('deactivate', function(e) {
      //               console.log('The Editor has initialized.', e);
      //   });
      // },
      plugins: [
        "advlist",
        "anchor",
        "autolink",
        //"autoresize",
        //"autosave",
        "lists",
        "codesample",
        "emoticons",
        "link",
        "hr",
        "save",
        "image",
        "charmap",
        "print",
        "preview",
        "anchor",
        "searchreplace",
        "visualblocks",
        "code",
        "fullscreen",
        "insertdatetime",
        "media",
        "nonbreaking",
        "pagebreak",
        "table",
        "paste",
        "code",
        "help",
        "wordcount",
        "tabfocus",
        "template",
        "textpattern",
        "visualblocks",
        "visualchars",
      ],
      menubar: 'file edit',
      menu: {
        file: { title: 'File', items: 'newdocument restoredraft | preview | print ' },
        edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
      },
      toolbar: "formatselect fontselect fontsizeselect | " +
        "bold italic underline strikethrough superscript subscript | forecolor backcolor removeformat | bullist numlist outdent indent | alignleft aligncenter alignright alignjustify | " +
        "image media link | table tabledelete tableprops tablerowprops tablecellprops tableinsertrowbefore tableinsertrowafter tabledeleterow tableinsertcolbefore tableinsertcolafter tabledeletecol | " +
        " hr charmap emoticons code codesample restoredraft pagebreak nonbreaking insertdatetime  spellchecker toc visualblocks visualchars wordcount help ",
      toolbar_mode: 'sliding',
      font_css: 'fonts.css',
      fontsize_formats: "8px 9px 10px 11px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 36px",
      image_advtab: true,
      extended_valid_elements: "svg[*],defs[*],pattern[*],desc[*],metadata[*],g[*],mask[*],path[*],line[*],marker[*],rect[*],circle[*],ellipse[*],polygon[*],polyline[*],linearGradient[*],radialGradient[*],stop[*],image[*],view[*],text[*],textPath[*],title[*],tspan[*],glyph[*],symbol[*],switch[*],use[*],filter[*],feFlood[*],feColorMatrix[*],feOffset[*],feGaussianBlur[*],feComposite[*],feBlend[*]"
    },
  },
  mutations: {

  },
  actions: {

  },
  getters: {
    getEditorOptions(state) {
      return state.editorOptions
    },
    getEditorKey(state) {
      return state.apiKey
    }
  }
};