import Keycloak from "keycloak-js";
import store from "@/store";

export default function keycloackInit() {
  const initOptions = {
    url: "https://auth.ined.ru/auth",
    realm: "Ined_users",
    clientId: "ined_client",
    onLoad: "login-required"
  };

  const keycloak = Keycloak(initOptions);
  keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
    if (!auth) {
      console.log("AUTH FAILED");
    } else {
      store.dispatch('setAuthData', keycloak);
    }
  })
    .catch(() => {
      console.log("AUTHENTICATION FAILED");
    });
}