import request from "@/services/request";
import axios from "axios";
//import Vue from "vue";

export default {
  state: {
    acts: [],
    actsLoaded: false,
    selectedActIndexes: [],
    // acts: [
    //   {
    //     title: "Законы и подзаконные акты",
    //     path: "/zakony",
    //     id: 0,
    //     items: [
    //       {
    //         title: "Законы",
    //         id: 0,
    //         parentId: 0,
    //         blocks: [
    //           {
    //             title: "Федеральные законы Российской Федерации",
    //             id: 0,
    //             parentId: 0,
    //             items: [
    //               {
    //                 id: 0,
    //                 parentId: 0,
    //                 title: "Об образовании в Российской Федерации",
    //                 description:
    //                   "Федеральный закон от 29 декабря 2012 года № 273-ФЗ (ред. 30.12.2021)",
    //                 link: "http://kremlin.ru/static/pdf/constitution.pdf?6fbd2dc717",
    //                 modalSource: "В данном виде документ опубликован не был. Первоначальный текст документа опубликован в изданиях " +
    //                   "Собрание законодательства РФ\", 29.07.2002, N 30, ст. 3032, Российская газета\", N 140, 31.07.2002,",
    //                 modalComment: "Начало действия редакции - 29.12.2021. Изменения, внесенные Федеральным законом от 01.07.2021 N 274-ФЗ, вступают в силу по истечении 180 дней после дня официального опубликования. Остальные изменения, внесенные указанным Законом, вступают в силу со дня официального опубликования (опубликован на Официальном интернет-портале правовой информации http://pravo.gov.ru - 01.07.2021) и с 24 августа 2021 года.",
    //                 modalTitle: "Федеральный закон от 25.07.2002 N 115-ФЗ (ред. от 02.07.2021) \"О правовом положении иностранных граждан в Российской Федерации\" (с изм. и доп., вступ. в силу с 29.12.2021)"
    //               }
    //             ]
    //           }
    //         ]
    //       },
    //       {
    //         title: "Подзаконные акты",
    //         id: 1,
    //         parentId: 0,
    //         blocks: [
    //           {
    //             title: "Акты Президента Российской Федерации",
    //             id: 0,
    //             parentId: 1,
    //             items: [
    //               {
    //                 id: 0,
    //                 parentId: 0,
    //                 title:
    //                   "Об определении порядка продления действия мер по обеспечению санитарно-эпидемиологического благополучия населения в субъектах Российской Федерации в связи с распространением новой коронавирусной инфекции (COVID-19)",
    //                 description:
    //                   "Указ Президента Российской Федерации от 11 мая 2020 года № 316",
    //                 link: "http://kremlin.ru/static/pdf/constitution.pdf?6fbd2dc717",
    //                 modalSource: "В данном виде документ опубликован не был. Первоначальный текст документа опубликован в изданиях " +
    //                   "Собрание законодательства РФ\", 29.07.2002, N 30, ст. 3032, Российская газета\", N 140, 31.07.2002,",
    //                 modalComment: "Начало действия редакции - 29.12.2021. Изменения, внесенные Федеральным законом от 01.07.2021 N 274-ФЗ, вступают в силу по истечении 180 дней после дня официального опубликования. Остальные изменения, внесенные указанным Законом, вступают в силу со дня официального опубликования (опубликован на Официальном интернет-портале правовой информации http://pravo.gov.ru - 01.07.2021) и с 24 августа 2021 года.",
    //                 modalTitle: "Федеральный закон от 25.07.2002 N 115-ФЗ (ред. от 02.07.2021) \"О правовом положении иностранных граждан в Российской Федерации\" (с изм. и доп., вступ. в силу с 29.12.2021)"
    //               }
    //             ]
    //           },
    //           {
    //             title: "Акты Правительства Российской Федерации",
    //             id: 1,
    //             parentId: 1,
    //             items: [
    //               {
    //                 id: 0,
    //                 parentId: 1,
    //                 title:
    //                   "Об установлении на 2022 год квоты приема на целевое обучение по образовательным программам высшего образования за счет бюджетных ассигнований федерального бюджета",
    //                 description:
    //                   "Распоряжение Правительства Российской Федерации от 23 ноября 2021 года № 3303-р",
    //                 link: "http://kremlin.ru/static/pdf/constitution.pdf?6fbd2dc717",
    //                 modalSource: "В данном виде документ опубликован не был. Первоначальный текст документа опубликован в изданиях " +
    //                   "Собрание законодательства РФ\", 29.07.2002, N 30, ст. 3032, Российская газета\", N 140, 31.07.2002,",
    //                 modalComment: "Начало действия редакции - 29.12.2021. Изменения, внесенные Федеральным законом от 01.07.2021 N 274-ФЗ, вступают в силу по истечении 180 дней после дня официального опубликования. Остальные изменения, внесенные указанным Законом, вступают в силу со дня официального опубликования (опубликован на Официальном интернет-портале правовой информации http://pravo.gov.ru - 01.07.2021) и с 24 августа 2021 года.",
    //                 modalTitle: "Федеральный закон от 25.07.2002 N 115-ФЗ (ред. от 02.07.2021) \"О правовом положении иностранных граждан в Российской Федерации\" (с изм. и доп., вступ. в силу с 29.12.2021)"
    //               }
    //             ]
    //           }
    //         ]
    //       }
    //     ]
    //   },
    //   {
    //     title: "Международные правовые акты",
    //     path: "/mezhdunarodnye",
    //     id: 1,
    //     items: []
    //   }
    // ]
  },
  mutations: {
    setActs(state, payload) {
      state.acts = payload;
      state.actsLoaded = true
    },
    addActSection(state, payload) {
      state.acts.map((act) => {
        if (act.path === payload.currentPath) {
          payload.newObj.id = act.items.length;
          payload.newObj.parentId = act.id;
          payload.newObj.blocks = [];
          act.items.push({...payload.newObj});
        }
      });
      //console.log("addActSection", state.acts);
    },
    deleteActSection(state, payload) {
      state.acts.map((act) => {
        if (act.path === payload.currentPath) {
          act.items = act.items.filter(item => item.id !== payload.id);
          let counter = 0;
          act.items.map((item) => {
            item.id = counter;
            counter++;
          });
        }
      });
      //console.log("deleteActSection", state.acts);
    },
    addActSubsection(state, payload) {
      state.acts.map((act) => {
        if (act.path === payload.currentPath) {
          act.items?.map((item) => {
            if (item.id === payload.sectionId) {
              payload.newObj.id = item.blocks.length;
              payload.newObj.parentId = payload.sectionId;
              payload.newObj.items = [];
              item.blocks.push({...payload.newObj});
            }
          });
        }
      });
      //console.log("addAct", state.acts);
    },
    deleteActSubsection(state, payload) {
      state.acts.map((act) => {
        if (act.path === payload.currentPath) {
          act.items?.map((item) => {
            if (item.id === payload.sectionId) {
              item.blocks = item.blocks?.filter((block) => block.id !== payload.id);
              let counter = 0;
              item.blocks.map((item) => {
                item.id = counter;
                counter++;
              });
            }
          });
        }
      });
      //console.log("deleteAct", state.acts);
    },
    addActItem(state, payload) {
      state.acts.map((act) => {
        if (act.path === payload.currentPath) {
          act.items?.map((item) => {
            if (item.id === payload.sectionId) {
              item.blocks?.map((block) => {
                if (block.id === payload.parentId) {
                  payload.newObj.id = block.items.length;
                  payload.newObj.parentId = payload.parentId;
                  block.items.push({...payload.newObj});
                }
              });
            }
          });
        }
      });
      //console.log("addAct", state.acts);
    },
    deleteActItem(state, payload) {
      state.acts.map((act) => {
        if (act.path === payload.currentPath) {
          act.items?.map((item) => {
            if (item.id === payload.sectionId) {
              item.blocks?.map((block) => {
                if (block.id === payload.parentId) {
                  block.items = block.items?.filter(item => item.id !== payload.id);
                  let counter = 0;
                  block.items.map((item) => {
                    item.id = counter;
                    counter++;
                  });
                }
              });
            }
          });
        }
      });
      //console.log("deleteAct", state.acts);
    },
    moveActItemUp(state, payload) {
      if (payload.id <= 0) {
        return;
      } else {
        state.acts.map((act) => {
          if (act.path === payload.currentPath) {
            act.items?.map((item) => {
              if (item.id === payload.sectionId) {
                item.blocks?.map((block) => {
                  if (block.id === payload.parentId) {
                    block.items[payload.id - 1].id = payload.id;
                    block.items[payload.id].id = payload.id - 1;
                    block.items?.splice(
                      payload.id - 1,
                      2,
                      block.items[payload.id],
                      block.items[payload.id - 1]
                    );
                  }
                });
              }
            });
          }
        });
        //console.log("moveActItemUp", state.acts);
      }
    },
    moveActItemDown(state, payload) {
      state.acts.map((act) => {
        if (act.path === payload.currentPath) {
          act.items?.map((item) => {
            if (item.id === payload.sectionId) {
              item.blocks?.map((block) => {
                if (block.id === payload.parentId) {
                  if (payload.id >= block.items.length - 1) {
                    return
                  } else {
                    block.items[payload.id + 1].id = payload.id;
                    block.items[payload.id].id = payload.id + 1;
                    block.items?.splice(
                      payload.id,
                      2,
                      block.items[payload.id + 1],
                      block.items[payload.id]
                    );
                    //console.log("moveActItemDown", state.acts);
                  }
                }
              });
            }
          });
        }
      });

    },
    setSelectedActIndexes(state, payload) {
      state.selectedActIndexes = [...payload.actIndexes]
    }
  },
  actions: {
    async downloadActs({ commit }) {
      const res = await request({
        endpoint: `/api/get?id=acts`,
        method: "GET"
      });
      //console.log("SET_ACTS", res.data);
      commit("setActs", res.data);
    },
    async sendActs({ state }) {
      try {
        await axios.post(`/api/set?id=acts`, JSON.stringify(state.acts), {
          headers: {
            'Content-Type': 'text/plain'
          },
          responseType: 'text'
        })
            .then(() => alert("Данные отправлены"))
            .catch((e) => alert("Ошибка отправки данных " + e))
      }catch (e) {
        alert("Ошибка отправки данных " + e);
      }
    }
  },

  //     const res = await request({
  //       endpoint: `/api/set?id=acts`,
  //       method: "POST",
  //       body: JSON.stringify(state.acts)
  //     });
  //     if (res.status === 200) {
  //       //console.log("POST ACTS", res);
  //       alert('Данные отправлены')
  //     } else {
  //       //console.log("POST ACTS", res);
  //       alert('Ошибка отправки данных')
  //     }
  //   }
  // },
  getters: {
    getActs(state) {
      return state.acts;
    },
    getSelectedActData(state) {
      const actPageIndex = state.acts.findIndex(act => act.path === state.selectedActIndexes[0])
      if (actPageIndex !== -1) {
        const sectionId = state.selectedActIndexes[1]
        const subsectionId = state.selectedActIndexes[2]
        const actId = state.selectedActIndexes[3]
        return state.acts[actPageIndex].items[sectionId].blocks[subsectionId].items[actId]
      } else {
        return ''
      }
    },
    getActsLoadingStatus(state) {
      return state.actsLoaded
    }
  }
};