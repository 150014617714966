import request from "@/services/request";
import axios from "axios";

export default {
  state: {
    events: [
      // {
      //   id: 0,
      //   title: "Вебинар",
      //   text: "Текст вебинара",
      //   datetime: 1650559082000,
      //   materials: [
      //     {
      //       id: 0,
      //       parentId: 0,
      //       title: "Презентация вебинара по вопросам приема в 2022/23 учебном году на обучение в пределах квоты Правительства Российской Федерации призеров и победителей олимпиадми конкурсов, проводимых образовательными организациями самостоятельно",
      //       type: "powerpoint",
      //       linkText: "Скачать презентацию",
      //       link: "https://files.ined.ru/adminpanel/6047_35_c4e3188afed8396199ef9f8ba8524158.pdf"
      //     },
      //     {
      //       id: 1,
      //       parentId: 0,
      //       title: "Запись вебинара по вопросам приема в 2022/23 учебном году на обучение в пределах квоты Правительства Российской Федерации призеров и победителей олимпиад и конкурсов, проводимых образовательными организациями самостоятельно",
      //       type: "video",
      //       linkText: "",
      //       link: "https://vk.com/video_ext.php?oid=-212315036&id=456239105&hash=663deff54b791e05"
      //     }
      //   ]
      // },
      // {
      //   id: 1,
      //   title: "Конференция",
      //   text: "Текст конференции",
      //   datetime: 1650559082000,
      //   materials: [
      //     {
      //       id: 0,
      //       parentId: 1,
      //       title: "word",
      //       type: "word",
      //       linkText: "Скачать документ",
      //       link: "https://files.ined.ru/adminpanel/6047_35_c4e3188afed8396199ef9f8ba8524158.pdf"
      //     },
      //     {
      //       id: 1,
      //       parentId: 1,
      //       title: "Запись вебинара по вопросам приема в 2022/23 учебном году на обучение в пределах квоты Правительства Российской Федерации призеров и победителей олимпиад и конкурсов, проводимых образовательными организациями самостоятельно",
      //       type: "video",
      //       linkText: "",
      //       link: "https://vk.com/video_ext.php?oid=-212315036&id=456239105&hash=663deff54b791e05"
      //     }
      //   ]
      // },
      // {
      //   id: 2,
      //   title: "Семинар",
      //   text: "Текст семинара",
      //   datetime: 1650559082000,
      //   materials: [
      //     {
      //       id: 0,
      //       parentId: 2,
      //       title: "excel",
      //       type: "excel",
      //       linkText: "Скачать таблицу",
      //       link: "https://files.ined.ru/adminpanel/6047_35_c4e3188afed8396199ef9f8ba8524158.pdf"
      //     },
      //     {
      //       id: 1,
      //       parentId: 2,
      //       title: "Запись вебинара по вопросам приема в 2022/23 учебном году на обучение в пределах квоты Правительства Российской Федерации призеров и победителей олимпиад и конкурсов, проводимых образовательными организациями самостоятельно",
      //       type: "video",
      //       linkText: "",
      //       link: "https://vk.com/video_ext.php?oid=-212315036&id=456239105&hash=663deff54b791e05"
      //     }
      //   ]
      // }
    ],
    eventsLoaded: false
  },
  mutations: {
    setEvents(state, payload) {
      state.events = payload;
      state.eventsLoaded = true;
    },
    changeStatus(state, ind){
      const item = state.events[ind];
      state.events.splice(ind, 1, {...item, active: !item.active})
    },
    addEventItem(state, payload) {
      payload.newObj.id = state.events.length;
      payload.newObj.active = true;
      payload.newObj.materials = []
      state.events.push({ ...payload.newObj });
      //console.log("addEventItem", state.events);
    },
    deleteEventItem(state, payload) {
      state.events = state.events.filter((event) => event.id !== payload.id);
      let counter = 0;
      state.events.map((event) => {
        event.id = counter;
        counter++;
      });
      //console.log("deleteEventItem", state.events);
    },
    addEventItemMaterial(state, payload) {
      const eventId = Number(payload.currentPath.split("/")[2]);
      payload.newObj.id = state.events[eventId].materials.length;
      payload.newObj.parentId = eventId;
      state.events[eventId].materials.push({ ...payload.newObj });
      //console.log("addEventItemMaterial", state.events);
    },
    deleteEventItemMaterial(state, payload) {
      const eventId = Number(payload.currentPath.split("/")[2]);
      state.events[eventId].materials = state.events[eventId].materials.filter((material) => material.id !== payload.id);
      let counter = 0;
      state.events[eventId].materials.map((material) => {
        material.id = counter;
        counter++;
      });
      //console.log("deleteEventItemMaterial", state.events);
    },
  },
  actions: {
    async downloadEventList({ commit }) {
      const res = await request({
        endpoint: `/api/get?id=events`,
        method: "GET"
      });
      commit("setEvents", res.data);
    },
    async changeStatusEvent({state, commit, dispatch}, item){
      const ind = state.events.findIndex(el => el.id === item.id);
      commit('changeStatus', ind);
      await dispatch('sendEventList', state);
    },

    async sendEventList({ state }) {
      try {
        await axios.post(`/api/set?id=events`, JSON.stringify(state.events), {
          headers: {
            'Content-Type': 'text/plain'
          },
          responseType: 'text'
        })
            .then(() => alert("Данные отправлены"))
            .catch((e) => alert("Ошибка отправки данных " + e))
      }catch (e) {
        alert("Ошибка отправки данных " + e);
      }

    }
  },
    //   const res = await request({
    //     endpoint: `/api/set?id=events`,
    //     method: "POST",
    //     body: JSON.stringify(state.events)
    //   });
    //   if (res.status === 200) {
    //     console.log("POST INFO", res);
    //     alert("Данные отправлены");
    //   } else {
    //     console.log("POST INFO", res);
    //     alert("Ошибка отправки данных");
    //   }
    // }
  getters: {
    getEventList(state) {
      return state.events;
    },
    getEventListLoadingStatus(state) {
      return state.eventsLoaded;
    },
  }
};