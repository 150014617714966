import request from '@/services/request';

export default {
  state: {
    rosSotrInfo: [],
    rosSotrInfoLoaded: false,
  },
  mutations: {
    setRosSotrInfo(state, payload) {
      state.rosSotrInfo = payload;
      state.rosSotrInfoLoaded = true;
    },
    addRosSotrInfoItem(state, payload) {
      payload.newObj.id = state.rosSotrInfo.length;
      state.rosSotrInfo.push({ ...payload.newObj });
      //console.log("addRosSotrInfoItem", state.rosSotrInfo);
    },
    deleteRosSotrInfoItem(state, payload) {
      state.rosSotrInfo = state.rosSotrInfo.filter((item) => item.id !== payload.id);
      let counter = 0;
      state.rosSotrInfo.map((item) => {
        item.id = counter;
        counter++;
      });
      //console.log("deleteRosSotrInfoItem", state.rosSotrInfo);
    }
  },
  actions: {
    async downloadRosSotrInfo({ commit }) {
      const res = await request({
        endpoint: `/api/get?id=rosSotrInfo`,
        method: 'GET',
      });
      //console.log("SET_ROSSOTR", res.data);
      commit('setRosSotrInfo', res.data);
    },
    async sendRosSotrInfo({ state }) {
      const res = await request({
        endpoint: `/api/set?id=rosSotrInfo`,
        method: 'POST',
        body: JSON.stringify(state.rosSotrInfo),
      });
      if (res.status === 200) {
        //console.log("POST_ROSSOTR", res);
        alert('Данные отправлены')
      } else {
        //console.log("POST_ROSSOTR", res);
        alert('Ошибка отправки данных')
      }
    },
  },
  getters: {
    getRosSotrInfo(state) {
      return state.rosSotrInfo;
    },
  },
};