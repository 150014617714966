<template>
  <header class="header">
    <div class="container">
            <div @click="menuMobileDisplay = false">
              <router-link to="/">
<!--                <img-->
<!--                  v-if="isEnglish"-->
<!--                  alt="Логотип сайта"-->
<!--                  class="header__logo_english"-->
<!--                  src="@/assets/images/logo-priemvuz-blue-eng.svg"-->
<!--                />-->
                <img
                  alt="Логотип сайта"
                  class="header__logo"
                  src="@/assets/images/logo-header.svg"
                />
              </router-link>
            </div>
      <nav class="header__navigation">
        <!--        Отдельное меню для десктопа-->
        <ul class="navigation__links">
          <main-header-menu v-for="(nav, index) in getMenuItems" :key="index" :nav="nav"/>
        </ul>
      </nav>
      <!--      <div class="header__lang">-->
      <!--        <router-link title="Нажмите, чтобы изменить язык" to="/">-->
      <!--          <span :class="isEnglish ? '' : 'header__lang-active'" @click="setLanguage('RUS')">РУС</span>-->
      <!--        </router-link>-->
      <!--        <router-link title="Нажмите, чтобы изменить язык" to="/">-->
      <!--          <span :class="isEnglish ? 'header__lang-active' : ''" @click="setLanguage('ENG')">ENG</span>-->
      <!--        </router-link>-->
      <!--      </div>-->
      <div v-if="isAuthorised && !isMobile" class="admin__buttons">
        <router-link v-if="checkContentManagerStatus" to="/edit_navigation">
          <button class="admin__button" title="Редактировать раздел навигации">
            <svg
              viewBox="0 0 512 512"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m179.2,410.4l-77.3-77.4 272.5-272.9 77.3,77.4-272.5,272.9zm-96-38.3l56.9,57-79.2,22.3 22.3-79.3zm411.8-249l-106.2-106.4c-7.7-7.7-21.2-7.7-28.9-3.55271e-15l-301.3,301.8c-2.5,2.5-4.3,5.5-5.2,8.9l-41.6,148c-2,7.1 0,14.8 5.2,20 3.9,3.9 11.7,6.7 20,5.2l147.8-41.7c3.4-0.9 6.4-2.7 8.9-5.2l301.3-301.7c8-8 8-20.9 0-28.9z"
              />
            </svg>
          </button>
        </router-link>
        <button class="admin__button" @click="logoutContentManager">
          <svg
            style="transform: scaleX(-1)"
            viewBox="0 0 320.002 320.002"
            width="20"
            x="0px"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            y="0px"
          >
            <path
              d="M51.213,175.001h173.785c8.284,0,15-6.716,15-15c0-8.284-6.716-15-15-15H51.213l19.394-19.394
		c5.858-5.858,5.858-15.355,0-21.213c-5.857-5.858-15.355-5.858-21.213,0L4.396,149.393c-0.351,0.351-0.683,0.719-0.997,1.103
		c-0.137,0.167-0.256,0.344-0.385,0.515c-0.165,0.22-0.335,0.435-0.488,0.664c-0.14,0.209-0.261,0.426-0.389,0.64
		c-0.123,0.206-0.252,0.407-0.365,0.619c-0.118,0.22-0.217,0.446-0.323,0.67c-0.104,0.219-0.213,0.435-0.306,0.659
		c-0.09,0.219-0.164,0.442-0.243,0.664c-0.087,0.24-0.179,0.477-0.253,0.722c-0.067,0.222-0.116,0.447-0.172,0.672
		c-0.063,0.249-0.133,0.497-0.183,0.751c-0.051,0.259-0.082,0.521-0.119,0.782c-0.032,0.223-0.075,0.443-0.097,0.669
		c-0.048,0.484-0.073,0.971-0.074,1.457c0,0.007-0.001,0.015-0.001,0.022c0,0.007,0.001,0.015,0.001,0.022
		c0.001,0.487,0.026,0.973,0.074,1.458c0.022,0.223,0.064,0.44,0.095,0.661c0.038,0.264,0.069,0.528,0.121,0.79
		c0.05,0.252,0.119,0.496,0.182,0.743c0.057,0.227,0.107,0.456,0.175,0.681c0.073,0.241,0.164,0.474,0.248,0.71
		c0.081,0.226,0.155,0.453,0.247,0.675c0.091,0.22,0.198,0.431,0.3,0.646c0.108,0.229,0.21,0.46,0.33,0.685
		c0.11,0.205,0.235,0.4,0.354,0.599c0.131,0.221,0.256,0.444,0.4,0.659c0.146,0.219,0.309,0.424,0.466,0.635
		c0.136,0.181,0.262,0.368,0.407,0.544c0.299,0.364,0.616,0.713,0.947,1.048c0.016,0.016,0.029,0.034,0.045,0.05l45,45.001
		c2.93,2.929,6.768,4.394,10.607,4.394c3.838-0.001,7.678-1.465,10.606-4.393c5.858-5.858,5.858-15.355,0.001-21.213L51.213,175.001
		z"
            />
            <path
              d="M305.002,25h-190c-8.284,0-15,6.716-15,15v60c0,8.284,6.716,15,15,15s15-6.716,15-15V55h160v210.001h-160
		v-45.001c0-8.284-6.716-15-15-15s-15,6.716-15,15v60.001c0,8.284,6.716,15,15,15h190c8.284,0,15-6.716,15-15V40
		C320.002,31.716,313.286,25,305.002,25z"
            />
          </svg>
        </button>
      </div>
      <div class="header__navigation-mobile">
        <div class="navigation-mobile__btn" @click="showMenuMobile">
          <div
            :class="blockMenuBtnAnimation ? '' : menuMobileDisplay ? 'btn-item-open' : 'btn-item-close'"
            class="navigation-mobile__btn-item"></div>
          <div
            :class="blockMenuBtnAnimation ? '' : menuMobileDisplay ? 'btn-item-open' : 'btn-item-close'"
            class="navigation-mobile__btn-item"></div>
          <div
            :class="blockMenuBtnAnimation ? '' : menuMobileDisplay ? 'btn-item-open' : 'btn-item-close'"
            class="navigation-mobile__btn-item"></div>
        </div>
        <!--        Отдельное меню для мобильной версии-->
        <nav
          :class="menuMobileDisplay ? 'navigation-mobile__menu menu-active' : 'navigation-mobile__menu'"
        >
          <main-header-menu-mobile
            :is-menu-open="menuMobileDisplay"
            :nav-sections="getMenuItems"
            @hideMenuMobile="hideMenuMobile"
          />
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import MainHeaderMenu from "@/components/header/MainHeaderMenu";
import MainHeaderMenuMobile from "@/components/header/MainHeaderMenuMobile";

import keycloackInit from "@/services/keycloakInit";
import Keycloak from "keycloak-js";

export default {
  name: "MainHeader",
  components: {
    MainHeaderMenu,
    MainHeaderMenuMobile,
  },
  data() {
    return {
      lastScroll: 0,
      mobileWidth: null,
      blockMenuBtnAnimation: true,
      menuDisplay: false,
      menuMobileDisplay: false,
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.setIsMobile();
    });
    window.addEventListener("scroll", () => {
      if (!this.isMobile) {
        this.hideMenu()
      }
    });
  },
  computed: {
    getMenuItems() {
      return this.$store.getters.getNavSections;
    },
    // isEnglish() {
    //   return this.$store.getters.checkEnglishVersion;
    // },
    isMobile() {
      if (this.mobileWidth !== null) {
        return window.innerWidth <= 768 && this.mobileWidth;
      } else {
        return window.innerWidth <= 768;
      }
    },
    isAuthorised() {
      return this.$store.getters.getAuthData;
    },
    checkContentManagerStatus() {
      return this.$store.getters.getContentManagerStatus;
    }
  },
  methods: {
    setIsMobile() {
      this.mobileWidth = window.innerWidth <= 768;
      if (!this.isMobile) {
        this.blockMenuBtnAnimation = true;
      }
    },
    setLanguage(lang) {
      window.scrollTo(0, 0)
      this.$store.dispatch("setLanguage", {lang: lang});
    },
    // showMenu(event, item) {
    //   this.menuDisplay = true;
    //   if (item) {
    //     this.selectedNavItem = item;
    //   }
    // },
    // hideMenu() {
    //   this.menuDisplay = false;
    //   this.selectedNavItem = "";
    // },
    showMenuMobile() {
      this.blockMenuBtnAnimation = false;
      this.menuMobileDisplay = !this.menuMobileDisplay;
    },
    hideMenuMobile() {
      this.menuMobileDisplay = false;
      this.blockMenuBtnAnimation = true;
    },
    logoutContentManager() {
      if (localStorage.getItem("auth") === "true" && !(this.$store.getters.getAuthData instanceof Keycloak)) {
        keycloackInit();
        this.$store.dispatch("keycloakLogout");
      } else {
        this.$store.dispatch("keycloakLogout");
      }
    },
    hideMenu() {
      // Скрывает меню по скроллу вниз, показывает по скроллу вверх
      const scrollPosition = window.scrollY || document.documentElement.scrollTop
      if (scrollPosition > this.lastScroll) {
        this.$el.style.transform = 'translateY(calc(-100% - 4px))'
      } else if (scrollPosition < this.lastScroll) {
        this.$el.style.transform = 'translateY(0)'
      }
      this.lastScroll = scrollPosition
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.header {
  width: 100vw;
  min-width: $containerWidth;
  height: 98px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $white-color;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
  transition: transform 0.2s;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    min-width: $smPhoneWidth;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
  }

  .container {
    width: $containerWidth;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      width: 100%;
      max-width: $mobile-version-container;
      padding: 0 30px 0 5px;
    }

    @media (max-width: $mobile-version) and (orientation: landscape) {
      width: 100%;
      padding: 0 30px 0 5px;
    }

    &-mobile {
      width: $smPhoneWidth;
    }
  }

  &__logo {
    height: 75px;
    margin-right: 45px;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      height: 44px;
    }

    &_english {
      height: 53px;
      padding-left: 12px;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        height: 36px;
        padding: 3px 0 3px 8px;
      }
    }
  }

  &__navigation {
    height: 98px;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      height: 44px;
      display: none;
    }

    &-mobile {
      width: 29px;
      display: none;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        display: block;
      }

      .navigation-mobile {
        &__btn {
          width: 29px;
          height: 15px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          @keyframes btn-toggle-top-open {
            0% {
              top: 0
            }
            50% {
              top: 6px;
              transform: rotate(0);
            }
            100% {
              top: 6px;
              transform: rotate(45deg);
            }
          }

          @keyframes btn-toggle-middle-open {
            0% {
              opacity: 1
            }

            60%, 100% {
              opacity: 0
            }
          }

          @keyframes btn-toggle-bottom-open {
            0% {
              top: 12px
            }
            50% {
              top: 6px;
              transform: rotate(0);
            }
            100% {
              top: 6px;
              transform: rotate(-45deg);
            }
          }

          @keyframes btn-toggle-top-close {
            0% {
              top: 6px;
              transform: rotate(45deg);
            }
            50% {
              top: 6px;
              transform: rotate(0);
            }
            100% {
              top: 0;
            }
          }

          @keyframes btn-toggle-middle-close {
            0% {
              opacity: 0
            }

            60%, 100% {
              opacity: 1
            }
          }

          @keyframes btn-toggle-bottom-close {
            0% {
              top: 6px;
              transform: rotate(-45deg);
            }
            50% {
              top: 6px;
              transform: rotate(0);
            }
            100% {
              top: 12px;
            }
          }

          &-item, .btn-item-open, .btn-item-close {
            width: 29px;
            height: 3px;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background-color: $primary-color;
            border-radius: 10px;

            &:nth-child(1) {
              top: 0;
            }

            &:nth-child(2) {
              top: 6px;
            }

            &:nth-child(3) {
              top: 12px;
            }
          }

          .btn-item {
            &-close {
              &:nth-child(1) {
                top: 0;
                animation: btn-toggle-top-close 0.5s forwards;
                animation-iteration-count: 1;
              }

              &:nth-child(2) {
                top: 6px;
                animation: btn-toggle-middle-close 0.5s forwards;
                animation-iteration-count: 1;
              }

              &:nth-child(3) {
                top: 12px;
                animation: btn-toggle-bottom-close 0.5s forwards;
                animation-iteration-count: 1;
              }
            }

            &-open {
              &:nth-child(1) {
                top: 0;
                animation: btn-toggle-top-open 0.5s forwards;
              }

              &:nth-child(2) {
                top: 6px;
                animation: btn-toggle-middle-open 0.5s forwards;
              }

              &:nth-child(3) {
                top: 12px;
                animation: btn-toggle-bottom-open 0.5s forwards;
              }
            }
          }
        }

        &__menu {
          min-width: $smPhoneWidth;
          width: 100%;
          min-height: calc(100% - 60px);
          position: absolute;
          top: 60px;
          left: 0;
          background-color: $backgroundColor;
          transform: translateX(100%);
          transition: all 0.2s;
        }
      }

      .menu-active {
        transform: translateX(0);
      }
    }

    .navigation {
      &__menu {
        width: 100%;
        position: absolute;
        top: 98px;
        left: 0;
        padding: 43px 0 29px;
        background-color: $white-color;
        box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.1), 4px 4px 2px 0 rgba(0, 0, 0, 0.3);
      }

      &__links {
        height: 98px;
        display: flex;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0;

        @media (max-width: $mobile-version) and (orientation: portrait) {
          height: 44px;
        }
      }

    }
  }

  &__mobile-flex-right {
    display: flex;
    align-items: center;
  }

  .admin {
    &__buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__button {
      margin-left: 10px;
      padding: 6px 9px 8px 11px;
      font-size: $fontSizeL;
      background-color: #e7e7e8;
      border-radius: 10px;
      cursor: pointer;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        margin-left: 10px;
        padding: 0 6px 3px 8px;
        border-radius: 3px;
      }

      &:first-child {
        margin-left: 0;
      }

      > svg {
        width: 25px;

        @media (max-width: $mobile-version) and (orientation: portrait) {
          width: 15px;
        }
      }

      &:hover {
        color: $white-color;
        background-color: $primary-color;

        > svg {
          fill: $white-color;
        }
      }
    }
  }

  &__lang {
    width: 90px;
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    > a {
      color: $grey-color;
      text-align: right;

      > span:hover {
        color: $primary-color;
      }
    }

    @media (max-width: $mobile-version) and (orientation: portrait) {
      display: none;
    }

    &-active {
      color: $primary-color;
      font-weight: 700
    }
  }
}
</style>