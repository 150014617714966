<template>
  <footer class="footer">
    <div class="container">
      <a href="https://minobrnauki.gov.ru" target="_blank" rel="nofollow noopener noreferrer" class="footer__left">
        <img alt="Логотип Минобрнауки РФ" class="footer__image footer__left-img"
             src="@/assets/images/logo-minobr-footer-eng.svg" v-if="isEnglish">
        <img alt="Логотип Минобрнауки РФ" class="footer__image footer__left-img"
             src="@/assets/images/logo-minobr-footer.svg" v-else>
      </a>
      <div class="footer__right">
        <div class="footer__right-top">
          <!--          <router-link class="footer__image footer__right-top-img-eng" to="/" v-if="isEnglish">-->
          <!--            <img alt="Логотип сайта" src="@/assets/images/logo-priemvuz-white-footer-eng.svg" />-->
          <!--          </router-link>-->
          <router-link class="footer__image footer__right-top-img" to="/">
            <img :style="{width: isMobile ? '70px' : '110px'}" alt="Логотип сайта" src="@/assets/images/logo-footer.svg"/>
          </router-link>
          <div class="footer__text footer__right-top-text" v-if="isEnglish">
            <span v-if="isMobile">Official Website
            </span>
            <span v-else>Official Website</span>
          </div>
          <div class="footer__text footer__right-top-text" v-else>
            <span v-if="isMobile">Официальный сайт
            </span>
            <span v-else>Официальный сайт
            </span>
          </div>
        </div>
        <a class="footer__right-center" href="mailto:website-support@ined.ru" v-if="isEnglish" style="margin-top: -7px">
          website-support@ined.ru (site technical support)
        </a>
        <a class="footer__right-center" href="mailto:website-support@ined.ru" v-else>
          <span v-if="isMobile">website-support@ined.ru (тех. поддержка сайта)</span>
          <span v-else>website-support@ined.ru (техническая поддержка сайта)</span>
        </a>
        <div class="footer__right-bottom">
          <div class="footer__image footer__right-bottom-img">
            <a href="https://ined.ru/" target="_blank" rel="noopener noreferrer nofollow">
              <img alt="Логотип Интеробразование"
                   src="@/assets/images/logo-interobrazovanie-white.svg"/>
            </a>
          </div>
          <div class="footer__text footer__right-bottom-text" style="margin-top: 17px;" v-if="isEnglish">
            <span v-if="isMobile" style="display: block; margin-top: -15px;">© FSBI “Interobrazovanie”, 2022<br/>
              All content published on this website is protected<br/>
              under copyright law. Link to the source is required.
            </span>
            <span v-else>© FSBI “Interobrazovanie”, 2022<br/>
              All content published on this website is protected under copyright law. <br/>
              Link to the source is required.
            </span>
          </div>
          <div class="footer__text footer__right-bottom-text" v-else>
            <span v-if="isMobile">© ФГБУ “Интеробразование”, 2022<br/>
            При цитировании ссылка <br/> на первоисточник обязательна
          </span>
            <span v-else>© ФГБУ “Интеробразование”, 2022<br/>
            При цитировании ссылка на первоисточник обязательна
          </span>
          </div>
        </div>
      </div>
      <div id="sputnik-informer"></div>
    </div>
    <div id="bottom"></div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
  data() {
    return {
      mobileWidth: null
    };
  },
  mounted() {
    window.onresize = () => {
      this.mobileWidth = document.documentElement.clientWidth <= 768;
    };
  },
  computed: {
    isMobile() {
      if (this.mobileWidth !== null) {
        return window.innerWidth <= 768 && this.mobileWidth;
      } else {
        return window.innerWidth <= 768;
      }
    },
    isEnglish() {
      return this.$store.getters.checkEnglishVersion;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.footer {
  width: 100vw;
  height: 256px;
  min-width: $containerWidth;
  position: relative;
  justify-self: flex-end;
  padding: 41px 0 42px;
  color: $white-color;
  background-color: $primary-color;

  @media screen and (max-width: $mobile-version) and (orientation: portrait) {
    min-width: $smPhoneWidth;
    height: 400px;
    padding: 20px 0;
  }

  .container {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 30px;

    @media screen and (max-width: $mobile-version) and (orientation: portrait) {
      flex-direction: column;
      max-width: $smPhoneWidth;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__left {
    margin-right: 60px;
    padding-right: 42px;
    border-right: 2px solid $white-color;

    @media screen and (max-width: $mobile-version) and (orientation: portrait) {
      margin-right: 0;
      margin-bottom: 20px;
      padding: 0 80px 14px;
      border-right: none;
      border-bottom: 2px solid $white-color;
    }

    &-img {
      width: 158px;
      height: 173px;

      @media screen and (max-width: $mobile-version) and (orientation: portrait) {
        width: 131px;
        height: 144px;
      }
    }
  }

  &__right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: $mobile-version) and (orientation: portrait) {
      max-height: 106px;
      max-width: 296px;
      justify-content: flex-start;
      align-self: flex-start;
      padding-left: 10px;
      font-size: $fontSizeMobileXS;
      line-height: 11px;
    }

    &-top {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 30px;

      @media screen and (max-width: $mobile-version) and (orientation: portrait) {
        margin-bottom: 17px;
      }


      &-img {
        width: 125px;
        display: flex;
        justify-content: flex-start;
        flex-shrink: 0;

        @media screen and (max-width: $mobile-version) and (orientation: portrait) {
          width: 75px;
        }

        > img {
          height: 50px;
          cursor: pointer;

          @media screen and (max-width: $mobile-version) and (orientation: portrait) {
            height: 28px;
          }
        }

        &-eng {
          width: 125px;
          display: flex;
          justify-content: center;
          flex-shrink: 0;
          margin-left: -2px;

          @media screen and (max-width: $mobile-version) and (orientation: portrait) {
            width: 75px;
            margin-left: 0;
          }

          > img {
            height: 35px;
            cursor: pointer;
            margin-left: -6px;

            @media screen and (max-width: $mobile-version) and (orientation: portrait) {
              height: 22px;
            }
          }
        }
      }

      &-text {
        height: 50px;
        display: flex;
        align-items: center;
        //margin-left: 125px;
      }
    }

    &-center {
      margin-bottom: 20px;
      padding-left: 125px;
      color: #ffffff;

      @media screen and (max-width: $mobile-version) and (orientation: portrait) {
        padding-left: 75px;
        margin-bottom: 20px;
      }
    }

    &-bottom {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &-img {
        width: 125px;
        display: flex;
        justify-content: center;
        flex-shrink: 0;

        @media screen and (max-width: $mobile-version) and (orientation: portrait) {
          width: 75px;
        }
      }

      &-img > a > img {
        height: 75px;
        cursor: pointer;

        @media screen and (max-width: $mobile-version) and (orientation: portrait) {
          height: 45px;
        }
      }
    }
  }

  #sputnik-informer {
    position: absolute;
    bottom: 0;
    right: 30px;

    @media screen and (max-width: $mobile-version) and (orientation: portrait) {
      right: calc(50% - 44px);
      //bottom: -15px;
    }
  }
}

</style>