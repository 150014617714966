export default {
  state: {
    keycloak: localStorage.getItem("keycloak") ? JSON.parse(localStorage.getItem("keycloak")) : null
  },
  mutations: {
    ["SET_AUTH_DATA"](state, payload) {
      state.keycloak = payload;
      localStorage.setItem("keycloak", JSON.stringify(payload));
      localStorage.setItem("auth", 'true');
      //console.log(state.keycloak);
    }
  },
  actions: {
    async setAuthData({ commit }, payload) {
      commit("SET_AUTH_DATA", payload);
    },
    async keycloakLogout({ commit, state }) {
      if (state.keycloak) {
        await localStorage.removeItem("keycloak");
        await localStorage.removeItem("auth");
        state.keycloak.logout();
        commit("SET_AUTH_DATA", null);
      }
    }
  },
  getters: {
    getAuthData(state) {
      return state.keycloak;
    },
    getContentManagerStatus(state) {
      if (state.keycloak) {
        return state.keycloak.realmAccess.roles.indexOf("CONTENT_MANAGER_ROLE") !== -1;
      }
    }
  }
};