import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import keycloak from "@/store/keycloak";
import acts from "@/store/acts";
import toggleBlocks from "@/store/toggleBlocks";
import navSections from "@/store/navSections";
import toggleNumberBlocks from "@/store/toggleNumberBlocks";
import editor from "@/store/editor";
import rossotrudnichestvo from "./rossotrudnichestvo";
import sberCredit from "@/store/sberCredit";
import language from "@/store/language";
import events from "@/store/events";

Vue.use(Vuex);

const dataState = createPersistedState({
  paths: ['language']
})

export default new Vuex.Store({
  modules: {
    keycloak,
    navSections,
    toggleBlocks,
    toggleNumberBlocks,
    acts,
    editor,
    rossotrudnichestvo,
    sberCredit,
    language,
    events
  },
  plugins: [dataState]
})
;