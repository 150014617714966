import request from "@/services/request";

export default {
  state: {
    sberCreditInfo: { text: '' },
    sberCreditInfoLoaded: false
  },
  mutations: {
    setSberCreditInfo(state, payload) {
      state.sberCreditInfo = payload;
      state.sberCreditInfoLoaded = true;
    },
    // addRosSotrInfoItem(state, payload) {
    //   payload.newObj.id = state.sberCreditInfo.length;
    //   state.rosSotrInfo.push({ ...payload.newObj });
    //   console.log("addRosSotrInfoItem", state.sberCreditInfo);
    // },
    // deleteRosSotrInfoItem(state, payload) {
    //   state.rosSotrInfo = state.rosSotrInfo.filter((item) => item.id !== payload.id);
    //   let counter = 0;
    //   state.rosSotrInfo.map((item) => {
    //     item.id = counter;
    //     counter++;
    //   });
    //   console.log("deleteRosSotrInfoItem", state.rosSotrInfo);
    // }
  },
  actions: {
    async downloadSberCreditInfo({ commit }) {
      const res = await request({
        endpoint: `/api/get?id=sberCreditInfo`,
        method: "GET"
      });
      //console.log("SET_ROSSOTR", res.data);
      commit("setSberCreditInfo", res.data);
    },
    async sendSberCreditInfo({ state }) {
      const res = await request({
        endpoint: `/api/set?id=sberCreditInfo`,
        method: "POST",
        body: JSON.stringify(state.sberCreditInfo)
      });
      if (res.status === 200) {
        //console.log("POST_CREDIT", res);
        alert('Данные отправлены')
      } else {
        //console.log("POST_CREDIT", res);
        alert('Ошибка отправки данных')
      }
    }
  },
  getters: {
    getSberCreditInfo(state) {
      return state.sberCreditInfo;
    }
  }
};