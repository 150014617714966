import request from "@/services/request";
import toggleNumberBlocks from "@/store/toggleNumberBlocks";
import axios from "axios";
//import acts from "@/store/acts";

export default {
  state: {
    navSections: [],
    navSectionsLoaded: false,
  },
  mutations: {
    setNavSections(state, payload) {
      state.navSections = payload;
      state.navSectionsLoaded = true
    },
    addNavItem(state, payload) {
      state.navSections.map((nav) => {
        if (nav.id === payload.sectionId) {
          nav.sections?.map((section) => {
            if (section.id === payload.parentId) {
              payload.newObj.id = section.items.length;
              payload.newObj.parentId = payload.parentId;
              section.items.push({...payload.newObj});
              //console.log("addNavItem", section.items);
              let newPageObj
              if (payload.newObj.componentType === 'Список раскрывающихся блоков') {
                (async function addNewToggleBlockPage() {
                  newPageObj = {
                    path: payload.newObj.link,
                    title: payload.newObj.title,
                  }
                  const res = await request({
                    endpoint: `/api/toggleBlocks/sections`,
                    body: newPageObj,
                    method: "POST"
                  });
                  if (res.status !== 200 && res.status !== 201) {
                    alert('Ошибка добавления')
                  }
                })()
                //console.log(toggleBlocks.state.toggleBlocks)
              } else if (payload.newObj.componentType === 'Список раскрывающихся блоков с номерами') {
                newPageObj = {
                  title: payload.newObj.title,
                  path: payload.newObj.link,
                  items: []
                }
                toggleNumberBlocks.state.toggleNumberBlocks.push({...newPageObj})
                //console.log(toggleNumberBlocks.state.toggleNumberBlocks);
              } else if (payload.newObj.componentType === 'Список актов') {
                (async function addNewActPage() {
                  newPageObj = {
                    id: null,
                    title: payload.newObj.title,
                    path: payload.newObj.link,
                    parent: null,
                    parentId: null
                  }
                  const res = await request({
                    endpoint: `/api/sections`,
                    body: newPageObj,
                    method: "POST"
                  });
                  if (res.status !== 200) {
                    alert('Ошибка добавления')
                  }
                })()

                // newPageObj = {
                //   title: payload.newObj.title,
                //   path: payload.newObj.link,
                //   id: acts.state.acts.length,
                //   items: []
                // }
                // acts.state.acts.push({...newPageObj})
                //console.log(acts.state.acts);
              }
            }
          });
        }
      });
    },
    deleteNavItem(state, payload) {
      state.navSections.map((nav) => {
        if (nav.id === payload.sectionId) {
          nav.sections?.map((section) => {
            if (section.id === payload.parentId) {
              section.items = section.items.filter((item) => {
                return item.id !== payload.id;
              });
              let counter = 0;
              section.items.map((item) => {
                item.id = counter;
                counter++;
              });
              //console.log("deleteNavItem", section.items);
            }
          });
        }
      });
    },
    moveNavItemUp(state, payload) {
      if (payload.id <= 0) {
        return;
      } else {
        state.navSections.map((nav) => {
          if (nav.id === payload.sectionId) {
            nav.sections.map((section) => {
              if (section.id === payload.parentId) {
                section.items[payload.id - 1].id = payload.id;
                section.items[payload.id].id = payload.id - 1;
                section.items?.splice(
                  payload.id - 1,
                  2,
                  section.items[payload.id],
                  section.items[payload.id - 1]
                );
                //console.log("moveNavItemUp", section.items);
              }
            });
          }
        });
      }

    },
    moveNavItemDown(state, payload) {
      state.navSections.map((nav) => {
        if (nav.id === payload.sectionId) {
          nav.sections.map((section) => {
            if (section.id === payload.parentId) {
              if (payload.id >= section.items.length - 1) {
                return;
              } else {
                section.items[payload.id + 1].id = payload.id;
                section.items[payload.id].id = payload.id + 1;
                section.items?.splice(
                  payload.id,
                  2,
                  section.items[payload.id + 1],
                  section.items[payload.id]
                );
                //console.log("moveNavItemDown", section.items);
              }
            }
          });
        }
      });

    },
    addNavSection(state, payload) {
      state.navSections.map((nav) => {
        if (nav.id === payload.parentId) {
          payload.newObj.id = nav.sections.length;
          payload.newObj.parentId = payload.parentId;
          payload.newObj.items = [];
          nav.sections.push({...payload.newObj});
          let newPageObj
          if (payload.newObj.componentType === 'Список раскрывающихся блоков') {
            (async function addNewToggleBlockPage() {
              newPageObj = {
                path: payload.newObj.link,
                title: payload.newObj.title,
              }
              const res = await request({
                endpoint: `/api/toggleBlocks/sections`,
                body: newPageObj,
                method: "POST"
              });
              if (res.status !== 200 && res.status !== 201) {
                alert('Ошибка добавления')
              }
            })()
            //console.log(toggleBlocks.state.toggleBlocks)
          } else if (payload.newObj.componentType === 'Список раскрывающихся блоков с номерами') {
            newPageObj = {
              title: payload.newObj.title,
              path: payload.newObj.link,
              items: []
            }
            toggleNumberBlocks.state.toggleNumberBlocks.push({...newPageObj})
            //console.log(toggleNumberBlocks.state.toggleNumberBlocks);
          } else if (payload.newObj.componentType === 'Список актов') {
            (async function addNewActPage() {
              newPageObj = {
                id: null,
                title: payload.newObj.title,
                path: payload.newObj.link,
                parent: null,
                parentId: null
              }
              const res = await request({
                endpoint: `/api/sections`,
                body: newPageObj,
                method: "POST"
              });
              if (res.status !== 200) {
                alert('Ошибка добавления')
              }
            })()
            // newPageObj = {
            //   title: payload.newObj.title,
            //   path: payload.newObj.link,
            //   id: acts.state.acts.length,
            //   items: []
            // }
            // acts.state.acts.push({...newPageObj})
            //console.log(acts.state.acts);
          }
          //console.log("addNavSection", nav.sections);
        }
      });
    },
    deleteNavSection(state, payload) {
      state.navSections.map((nav) => {
        if (nav.id === payload.parentId) {
          nav.sections = nav.sections.filter((section) => {
            return section.id !== payload.id;
          });
          let counter = 0;
          nav.sections.map((section) => {
            section.id = counter;
            counter++;
          });
          //console.log("deleteNavSection", nav.sections);
        }
      });
    },
    moveNavSectionUp(state, payload) {
      if (payload.id <= 0) {
        return;
      } else {
        state.navSections.map((nav) => {
          if (nav.id === payload.parentId) {
            nav.sections[payload.id - 1].id = payload.id;
            nav.sections[payload.id].id = payload.id - 1;
            nav.sections?.splice(
              payload.id - 1,
              2,
              nav.sections[payload.id],
              nav.sections[payload.id - 1]
            );
            nav.sections?.map((section) => {
              section.items?.map((item) => {
                item.parentId = section.id;
              });
            });
            //console.log("moveNavSectionUp", nav.sections);
          }
        });
      }

    },
    moveNavSectionDown(state, payload) {
      state.navSections.map((nav) => {
        if (payload.id >= nav.sections.length - 1) {
          return;
        } else {
          if (nav.id === payload.parentId) {
            nav.sections[payload.id + 1].id = payload.id;
            nav.sections[payload.id].id = payload.id + 1;
            nav.sections?.splice(
              payload.id,
              2,
              nav.sections[payload.id + 1],
              nav.sections[payload.id]
            );
            nav.sections?.map((section) => {
              section.items?.map((item) => {
                item.parentId = section.id;
              });
            });
            //console.log("moveNavSectionDown", nav.sections);
          }
        }
      });
    },
    addNavMenu(state, payload) {
      payload.newObj.id = state.navSections.length;
      payload.newObj.sections = [];
      state.navSections.push({...payload.newObj});
      //console.log("addNavMenu", state.navSections);
    },
    deleteNavMenu(state, payload) {
      state.navSections = state.navSections.filter((item) => {
        return item.id !== payload.id;
      });
      let counter = 0;
      state.navSections.map((section) => {
        section.id = counter;
        counter++;
      });
      //console.log("deleteNavMenu", state.navSections);
    },
    moveNavMenuUp(state, payload) {
      if (payload.id <= 0) {
        return;
      } else {
        state.navSections[payload.id - 1].id = payload.id;
        state.navSections[payload.id].id = payload.id - 1;
        state.navSections.splice(
          payload.id - 1,
          2,
          state.navSections[payload.id],
          state.navSections[payload.id - 1]
        );
        state.navSections.map((nav) => {
          nav.sections?.map((section) => {
            section.parentId = nav.id;
          });
        });
      }
      //console.log("moveNavMenuUp", state.navSections);
    },
    moveNavMenuDown(state, payload) {
      if (payload.id >= state.navSections.length - 1) {
        return;
      } else {
        state.navSections[payload.id + 1].id = payload.id;
        state.navSections[payload.id].id = payload.id + 1;
        state.navSections.splice(
          payload.id,
          2,
          state.navSections[payload.id + 1],
          state.navSections[payload.id]
        );
        state.navSections.map((nav) => {
          nav.sections?.map((section) => {
            section.parentId = nav.id;
          });
        });
      }
      //console.log("moveNavMenuDown", state.navSections);
    },
    addContact(state, payload) {
      state.navSections.map((nav) => {
        if (nav.link === payload.currentPath) {
          nav.sections.map((section) => {
            if (section.id === +payload.contactTypeId) {
              if (payload.contactTypeId === "0") {
                payload.newObj.icon = "tel";
              } else if (payload.contactTypeId === "1") {
                payload.newObj.icon = "email";
              }
              payload.newObj.id = section.items.length;
              payload.newObj.parentId = +payload.contactTypeId;
              section.items.push({...payload.newObj});
              //console.log("addContact", section.items);
            }
          });
        }
      });

    },
    deleteContact(state, payload) {
      state.navSections.map((nav) => {
        if (nav.link === payload.currentPath) {
          nav.sections?.map((section) => {
            if (section.id === payload.parentId) {
              section.items = section.items?.filter(item => {
                  return item.id !== payload.id;
                }
              );
              let counter = 0;
              section.items.map((section) => {
                section.id = counter;
                counter++;
              });
              //console.log("deleteContact", section.items);
            }
          });
        }
      });
    }
  },
  actions: {
    async downloadNavSections({ commit }) {
      const res = await request({
        endpoint: `/api/get?id=navSections`,
        method: "GET"
      });
      //console.log("SET_NAV_SECTIONS", res.data);
      commit("setNavSections", res.data);
    },

    async sendNavSections({ state }) {
      try {
        await axios.post(`/api/set?id=navSections`, JSON.stringify(state.navSections), {
          headers: {
            'Content-Type': 'text/plain'
          },
          responseType: 'text'
        })
            .then(() => alert("Данные отправлены"))
            .catch((e) => alert("Ошибка отправки данных " + e))
      }catch (e) {
        alert("Ошибка отправки данных " + e);
      }
    }
  },

  //     const res = await request({
  //       endpoint: `/api/set?id=navSections`,
  //       method: "POST",
  //       body: JSON.stringify(state.navSections)
  //     });
  //     if (res.status === 200) {
  //       //console.log("POST NAV", res);
  //       alert('Данные отправлены')
  //     } else {
  //       //console.log("POST NAV", res);
  //       alert('Ошибка отправки данных')
  //     }
  //   }
  // },
  getters: {
    getNavSections(state) {
      return state.navSections;
    },
    getNavSectionsLoadingStatus(state) {
      return state.navSectionsLoaded
    },
    getComponentTypes(state) {
      let components = [];
      state.navSections.map(nav => {
        if (nav.componentType) {
          components.push(nav.componentType);
        }
        nav.sections.map(section => {
          if (section.componentType) {
            components.push(section.componentType);
          }
          section.items.map(item => {
            if (item.componentType) {
              components.push(item.componentType);
            }
          });
        });
      });
      const filteredComponents = [...new Set(components)];
      return filteredComponents;
    },
    getCardImages(state) {
      let images = [];
      let defaultImages = ['logo-budget']; // объявление нового элемента массива, работа без бд
      images.push(...defaultImages); //добавление нового элемента массива для списка картинок
      state.navSections.forEach((nav)  => {
        if (nav.image) {
          images.push(nav.image);
        }
        nav.sections.map(section => {
          if (section.image) {
            images.push(section.image);
          }
          section.items.map(item => {
            if (item.image) {
              images.push(item.image);
            }
          });
        });
      });
      const filteredImages = [...new Set(images)];
      return filteredImages;
    }
  }
};