export default {
  state: {
    isEnglish: false,
  },
  mutations: {
    TOGGLE_LANGUAGE(state, payload) {
      state.isEnglish = payload.lang !== 'RUS';
    },
  },
  actions: {
    setLanguage( {commit}, payload ) {
      commit('TOGGLE_LANGUAGE', payload);
    },
  },
  getters: {
    checkEnglishVersion(state) {
      return state.isEnglish;
    }
  }
};