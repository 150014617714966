<template>
  <div v-if="navSections" class="menu">
    <div class="menu__blocks">
      <main-header-menu-mobile-section
        v-for="(nav, index) of navSections"
        :key="index"
        :nav-section="nav" :nav-section-opened-id="navSectionOpenedId"
        class="menu__block"
        @hideMenuMobile="$emit('hideMenuMobile')"
        @setSectionOpenedId="setSectionOpenedId($event)"
      />
    </div>
    <div class="menu__bottom">
      <div class="menu__bottom-container">
        <button :style="{visibility: isAuthorised ? 'visible' : 'hidden'}" class="menu__bottom-btn"
                @click="logoutContentManager">
          <svg
            style="transform: scaleX(-1)"
            viewBox="0 0 320.002 320.002"
            width="20"
            x="0px"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            y="0px"
          >
            <path
              d="M51.213,175.001h173.785c8.284,0,15-6.716,15-15c0-8.284-6.716-15-15-15H51.213l19.394-19.394
		c5.858-5.858,5.858-15.355,0-21.213c-5.857-5.858-15.355-5.858-21.213,0L4.396,149.393c-0.351,0.351-0.683,0.719-0.997,1.103
		c-0.137,0.167-0.256,0.344-0.385,0.515c-0.165,0.22-0.335,0.435-0.488,0.664c-0.14,0.209-0.261,0.426-0.389,0.64
		c-0.123,0.206-0.252,0.407-0.365,0.619c-0.118,0.22-0.217,0.446-0.323,0.67c-0.104,0.219-0.213,0.435-0.306,0.659
		c-0.09,0.219-0.164,0.442-0.243,0.664c-0.087,0.24-0.179,0.477-0.253,0.722c-0.067,0.222-0.116,0.447-0.172,0.672
		c-0.063,0.249-0.133,0.497-0.183,0.751c-0.051,0.259-0.082,0.521-0.119,0.782c-0.032,0.223-0.075,0.443-0.097,0.669
		c-0.048,0.484-0.073,0.971-0.074,1.457c0,0.007-0.001,0.015-0.001,0.022c0,0.007,0.001,0.015,0.001,0.022
		c0.001,0.487,0.026,0.973,0.074,1.458c0.022,0.223,0.064,0.44,0.095,0.661c0.038,0.264,0.069,0.528,0.121,0.79
		c0.05,0.252,0.119,0.496,0.182,0.743c0.057,0.227,0.107,0.456,0.175,0.681c0.073,0.241,0.164,0.474,0.248,0.71
		c0.081,0.226,0.155,0.453,0.247,0.675c0.091,0.22,0.198,0.431,0.3,0.646c0.108,0.229,0.21,0.46,0.33,0.685
		c0.11,0.205,0.235,0.4,0.354,0.599c0.131,0.221,0.256,0.444,0.4,0.659c0.146,0.219,0.309,0.424,0.466,0.635
		c0.136,0.181,0.262,0.368,0.407,0.544c0.299,0.364,0.616,0.713,0.947,1.048c0.016,0.016,0.029,0.034,0.045,0.05l45,45.001
		c2.93,2.929,6.768,4.394,10.607,4.394c3.838-0.001,7.678-1.465,10.606-4.393c5.858-5.858,5.858-15.355,0.001-21.213L51.213,175.001
		z"
            />
            <path
              d="M305.002,25h-190c-8.284,0-15,6.716-15,15v60c0,8.284,6.716,15,15,15s15-6.716,15-15V55h160v210.001h-160
		v-45.001c0-8.284-6.716-15-15-15s-15,6.716-15,15v60.001c0,8.284,6.716,15,15,15h190c8.284,0,15-6.716,15-15V40
		C320.002,31.716,313.286,25,305.002,25z"
            />
          </svg>
        </button>
        <router-link class="menu__bottom-lang" to="/">
          <!--          <span-->
          <!--            :style="{color: isEnglish ? '#49606B' : '#13387D','font-weight': isEnglish ? 400 : 700}"-->
          <!--            @click="setLanguage('RUS')"-->
          <!--          >РУС-->
          <!--          </span>  -->
          
          
          <!--          <span-->
          <!--            style="'color: #13387D; font-weight: 700"-->
          <!--            @click="setLanguage('RUS')"-->
          <!--          >РУС-->
          <!--          </span>-->
          
          
          <!--          <span-->
          <!--            :style="{ color: isEnglish ? '#13387D' : '#49606B', 'font-weight': isEnglish ? 700 : 400}"-->
          <!--            @click="setLanguage('ENG')"-->
          <!--          >ENG-->
          <!--          </span>-->
          
          
          <!--          <span-->
          <!--            style="'color: #49606B, font-weight: 400"-->
          <!--            @click="setLanguage('ENG')"-->
          <!--          >ENG-->
          <!--          </span>-->
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>


import MainHeaderMenuMobileSection from "./MainHeaderMenuMobileSection";
import Keycloak from "keycloak-js";
import keycloackInit from "@/services/keycloakInit";

export default {
  name: "MainHeaderMenuMobile",
  components: {MainHeaderMenuMobileSection},
  props: {
    navSections: {
      type: [Array, String],
      default: () => []
    },
    isMenuOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      navSectionOpenedId: null
    };
  },
  watch: {
    isMenuOpen() {
      if (!this.isMenuOpen) {
        this.navSectionOpenedId = null
        document.documentElement.style.overflowY = 'auto'
      } else {
        document.documentElement.style.overflowY = 'hidden'
      }
    }
  },
  computed: {
    isAuthorised() {
      return this.$store.getters.getAuthData;
    },
    // isEnglish() {
    //   return this.$store.getters.checkEnglishVersion;
    // }
  },
  methods: {
    // Этот метод устанавливает id открытой секции, нужно, чтобы секция закрывалась при открытии другой
    // или все секции закрывались при нажатии на логотип или переключении языка
    setSectionOpenedId(event) {
      this.navSectionOpenedId = event.navId;
    },
    setLanguage(lang) {
      window.scrollTo(0, 0)
      this.navSectionOpenedId = null;
      this.$emit("hideMenuMobile");
      this.$store.dispatch("setLanguage", {lang: lang});
    },
    logoutContentManager() {
      if (localStorage.getItem("auth") === "true" && !(this.$store.getters.getAuthData instanceof Keycloak)) {
        keycloackInit();
        this.$store.dispatch("keycloakLogout");
      } else {
        this.$store.dispatch("keycloakLogout");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.menu {
  width: 100%;
  height: calc(100vh - 60px);
  min-height: calc(100vh - 60px);
  position: relative;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 180;
  
  &__blocks {
    width: 100%;
    position: absolute;
    top: 3px;
    left: 0;
    z-index: 200;
  }
  
  &__block {
    min-width: $smPhoneWidth;
    width: 100%;
    margin: 0 auto;
    padding: 12px 0 12px 0;
    border-bottom: 1px solid $greyColorBorder;
  }
  
  &__bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 60;
    
    &-container {
      max-width: $mobile-version-container;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      padding: 20px 35px 65px 10px;
    }
    
    &-lang {
      width: 85px;
      display: flex;
      justify-content: space-between;
      color: $grey-color;
      z-index: 90;
    }
    
    &-btn {
      padding: 4px 8px 7px 11px;
      font-size: $fontSizeL;
      background-color: #e7e7e8;
      border-radius: 3px;
      
      > svg {
        width: 23px;
      }
      
      &:active {
        color: $white-color;
        background-color: $primary-color;
        
        > svg {
          fill: $white-color;
        }
      }
    }
  }
  
}


</style>