import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/components/mainPage/MainPage"),
    meta: {
      title: "Главная страница"
    }
  },
  {
    path: "/auth",
    component: () => import("@/components/AuthPage")
  },
  {
    path: "/edit_navigation",
    component: () => import("@/components/admin/AdminNavPage"),
    meta: {
      title: "Редактирование разделов сайта",
      isContentManager: true
    }
  },
  {
    path: "/allreviews",
    component: () => import("@/components/pages/AllReviews")
  },
  {
    path: "/adminreviews",
    component: () => import("@/components/admin/AdminReviews")
  },
  {
    path: "/:name",
    component: () => import("@/components/GenerateView"),
  },
  {
    path: "/:name/:id",
    component: () => import("@/components/GenerateView"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.path !== "/auth") {
    localStorage.setItem("Prev_route", to.path);
  }
  const prevRoutePath = localStorage.getItem("Prev_route");
  let isAuthorised = store.getters.getAuthData
  if (!isAuthorised) {
    const checkAuth = setInterval(() => {
      isAuthorised = store.getters.getAuthData
      if (to.path === "/auth" && isAuthorised) {
        next({ path: prevRoutePath });
        clearInterval(checkAuth)
      }
    },1000)
  } else {
    if (to.path === "/auth" && isAuthorised) {
      next({ path: prevRoutePath });
    }
  }

  if (to.matched.some((record) => record.meta.isContentManager)) {
    if (!store.getters.getContentManagerStatus) {
      next({
        path: "/"
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const orgsPages = [] //['/events', '/faqs', '/pisma']
  //const nextRoutePath = localStorage.getItem("Next_route");
  if (orgsPages.findIndex(path => path === to.path) !== -1) {
    const isAuthorised = store.getters.getAuthData
    if (!isAuthorised) {
      next({ path: "/auth" });
    } else {
      next()
    }
  } else {
    next()
  }
});

// router.afterEach(to => {
//   if (to.path !== "/auth") {
//     localStorage.setItem("Prev_route", to.path);
//   }
// });

export default router;
