<template>
  <main-loader v-if="!loadingStatus"></main-loader>
  <div v-else id="app">
    <div id="top"></div>
    <main-header />
    <div class="page-nav-btn page-nav-btn-double"
         v-if="isMobile"
    >
      <div @click="scrollToTop">
        <img
          alt=""
          class="page-nav-btn-up"
          src="@/assets/images/icon-arrow-background-btn-up.svg"
        />
      </div>
      <div @click="scrollToBottom">
        <img
          alt=""
          class="page-nav-btn-down"
          src="@/assets/images/icon-arrow-background-btn-down.svg"
        />
      </div>
    </div>
    <div class="page-nav-btn"
         :class="scrollPosition === 'middle' ? 'page-nav-btn-double' : 'page-nav-btn-single'"
         v-else
    >
      <div v-if="scrollPosition !== 'top'" class="page-nav-btn-up" @click="scrollUp">
        <img
          alt=""
          src="@/assets/images/icon-arrow-background-btn-up.svg"
        />
      </div>
      <div v-if="scrollPosition !== 'bottom'" class="page-nav-btn-down" @click="scrollDown">
        <img
          alt=""
          src="@/assets/images/icon-arrow-background-btn-down.svg"
        />
      </div>
    </div>
    <div class="app__main">
      <keep-alive include="AdminGenerateView" :max="3">
        <router-view class="pagesHeight"></router-view>
      </keep-alive>
    </div>
    <main-footer />
  </div>
</template>
<script>
import MainHeader from "@/components/header/MainHeader";
import MainFooter from "@/components/MainFooter";
import MainLoader from "@/components/MainLoader";

export default {
  name: "app",
  components: {
    MainLoader,
    MainHeader,
    MainFooter
  },
  data() {
    return {
      mobileWidth: null,
      scrollPosition: "top"
    };
  },
  mounted() {
    this.$store.dispatch("downloadNavSections");
    window.addEventListener("scroll", () => {
      this.clientPosition();
    });
    window.addEventListener("resize", () => {
      this.mobileWidth = window.innerWidth <= 768;
    });
  },
  computed: {
    loadingStatus() {
      return this.$store.getters.getNavSectionsLoadingStatus;
    },
    isMobile() {
      if (this.mobileWidth !== null) {
        return window.innerWidth <= 768 && this.mobileWidth;
      } else {
        return window.innerWidth <= 768;
      }
    },
  },
  methods: {
    clientPosition() {
      if (document.documentElement.scrollTop === 0) {
        this.scrollPosition = "top";
      } else if (document.documentElement.scrollHeight - 5 < document.documentElement.scrollTop + document.documentElement.clientHeight) {
        this.scrollPosition = "bottom";
      } else {
        this.scrollPosition = "middle";
      }
    },
    scrollToTop() {
      const topOfPage = document.documentElement.querySelector("#top");
      topOfPage.scrollIntoView({ block: "start" });
    },
    scrollToBottom() {
      const bottomOfPage = document.documentElement.querySelector("#bottom");
      bottomOfPage.scrollIntoView({ block: "end" });
    },
    scrollUp() {
      window.scrollTo(0, 0);
    },
    scrollDown() {
      const documentHeight = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
      );
      window.scrollTo(0, documentHeight);
    }
  }
};


/*
Что еще нужно сделать:
* - Бэк для навигации
* - Отдельный бэк для контактов с одной базой, чтобы контакты попадали в навигацию, так как они отображаются в меню хедера
* - Бэк для блоков с номерами в разделе для поступающих
* - Бэк для контактов Россотрудничества
*   Нужен бэк для раздела мероприятий
*/
</script>

<style lang="scss">
@import "src/assets/styles/variables";

#top, #bottom {
  width: 50vw;
  height: 0;
  z-index: -10
}

#top {
  position: absolute;
  top: 0;
  left: 0;
}

#bottom {
  position: absolute;
  bottom: 0;
  left: 0;
}

#app {
  width: 100vw;
  min-width: $containerWidth;
  height: 100%;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: $backgroundColor;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    min-width: $smPhoneWidth;
  }
}

.app__main {
  width: 100vw;
  min-width: $containerWidth;
  position: relative;
  //margin-right: calc(-1 * (100vw - 100%));
  //margin: 0 auto;
  padding-top: 100px;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    min-width: $smPhoneWidth;
    padding-top: 60px;
  }
}

.container {
  max-width: $containerWidth;
  margin: 0 auto;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    min-width: $smPhoneWidth;
    width: 100vw;
    max-width: $mobile-version-container;
    padding-left: 10px;
    padding-right: 10px;
  }

  @media (max-width: $mobile-version) and (orientation: landscape) {
    padding: 0 30px 0 5px;
  }
}

.pagesHeight {
  min-height: calc(100vh - 100px - 256px);
  position: relative;
  padding: 20px 0 30px;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    min-height: calc(100vh - 60px - 400px);
    padding: 13px 18px 45px;
  }
}

.page-nav {

  &-btn {
    //min-width: 43px;
    position: fixed;
    bottom: 20px;
    right: auto;
    left: calc((100vw - 1140px) / 2 + 1150px);
    //left: calc(50vw + 580px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    background: rgba($greyColorLight, $alpha: 0.6);
    border-radius: 50px;
    transition: all 0.2s;
    z-index: 10;

    @media (max-width: $containerWidth) and (orientation: landscape) {
      right: 20px;
      left: auto;
    }

    @media (max-width: $mobile-version) and (orientation: portrait) {
      //min-width: 25px;
      right: auto;
      left: calc(50vw + 235px);
    }

    @media (max-width: 540px) and (orientation: portrait) {
      left: auto;
      right: 20px;
    }

    &-double {
      max-height: 87px;
      min-height: 87px;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        min-height: 80px;
      }
    }

    &-single {
      max-height: 46px;
      min-height: 46px;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        min-height: 22px;
      }
    }

    &-up, &-down {
      cursor: pointer;
      z-index: 10;

      > img {
        width: 20px;

        @media (max-width: $mobile-version) and (orientation: portrait) {
          width: 10px;
        }
      }
    }

    &-up {
      padding: 12px 12px 15px;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        padding: 10px 7px 15px;
      }
    }

    &-down {
      padding: 15px 12px 14px;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        padding: 15px 7px 10px;
      }
    }
  }
}

*::-webkit-scrollbar {
  width: 5px;
  z-index: 999;
}

*::-webkit-scrollbar-thumb {
  background-color: $primary-color;
  border-radius: 5px;
  z-index: 999;
}


// классы для админки, которые контент-менеджер вставляет в код

// блок-кнопка для модалки, используется в toggleNumberBlock
.modal-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  color: $black-color;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: $boxShadowLight;
  cursor: pointer;

  &:hover {
    color: $primary-color;
    box-shadow: 8px 8px 4px 0 rgba(0, 0, 0, 0.5);
  }
}

// текст синеет по hover
.blue-text {
  color: $black-color;

  &:hover {
    color: $primary-color!important;
  }
}

// всплывашка с текстом, администратор добавляет class="tip-hover-container" в текст, по которому должен быть hover, и
// <span class="tip-hover">Текст всплывашки</span>
.tip-hover {
  width: fit-content;
  height: auto;
  position: absolute;
  top: -40px;
  left: 100%;
  display: none;
  padding: 10px 15px;
  color: #000000;
  font-size: $fontSizeMain;
  font-weight: normal;
  white-space: nowrap;
  border: 1px solid $greyColorBorder;
  border-radius: 5px;
  cursor: default;
  background-color: $white-color;
  z-index: 100;

  &-container {
    position: relative;
    text-decoration: underline;
    cursor: pointer;

    &:hover > .tip-hover, &:active > .tip-hover  {
      display: block;
    }
  }
}

</style>